import React from "react";
import Layout from "../components/Layout/Layout";
import Text from "../components/Text/Text";
import { Col, Grid, Row } from 'react-flexbox-grid';


/**
 * Info template
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Info = (props) => {
    const { pageContext } = props;
    const { layoutData } = pageContext;

    return <Layout data={layoutData}>
        <section className="resume">
            <Row>
                <Col lgOffset={2} lg={4}>
                    <p>Lennart Bruger</p>
                    <p><a href="mailto:hello@len.click">hello@len.click</a></p>
                </Col>
                <Col lg={4}>
                    <p><br></br><a href="linkedin.com/in/metlennart" target="_blank">linkedin.com/in/metlennart</a></p>
                    <br></br>
                    </Col>
            </Row>
            <Row>
            <Col lgOffset={2} lg={8}>
            <h2>I love using design to clarify and simplify the complex process of interactive  projects.<br></br><br></br>
My experience in the fields of concept, design and development makes me the ideal person to make the complexities of building a website understandable to all parties involved.<br></br><br></br> 
I'm a conceptual thinker, using a detailed and precise approach to design, always ready to explain what steps need to be taken for the best result.<br></br><br></br>
Recent clients include: Museum Arnhem, Lekker Company, Allard Pierson,  Holland Festival, Buurkracht, Lemming Film, Rijnboutt, WRK Architects, Insane, Platform ACCT.<br></br><br></br></h2>
                
                </Col>
            </Row>
            <Row>
                <Col lgOffset={2} lg={4}>
                    <p>Specialties:</p>
                    <h2>
                        Interaction Design <br></br>
                        Digital Design <br></br>
                        Responsive Web Design <br></br>
                        Motion design<br></br>
                        Concept
                    </h2>
                </Col>
                <Col lg={4}>
                <p>Skills:</p>
                    <h2>
                        Sketch / Figma<br></br>
                        Javascript<br></br>
                        JQuery / p5js<br></br>
                        HTML / CSS<br></br>
                        PHP / Wordpress
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col lgOffset={2} lg={8}>
                    <p>Experience</p>
                    <h2>Thonik</h2>
                    <p>Feb 2017 - Nov 2020 (3 years 10 months)</p>
                    <p>Interaction Designer</p>

                    <h2>Studio Stomp</h2>
                    <p>Senior Creative</p>
                    <p>Apr 2015 - Feb 2017 (1 year 11 months)</p>
                    <br></br>
                    <p>Interaction Designer</p>
                    <p>Apr 2012 - Mar 2015 (3 years)</p>

                    <h2>Amsterdam University of Applied Sciences</h2>
                    <p>Projectcoach CMD</p>
                    <p>Sep 2015 - May 2016 (9 months)</p>

                    <h2>metLennart</h2>
                    <p>Owner</p>
                    <p>Aug 2008 - Apr 2012 (3 years 9 months)</p>

                    <h2>Plustien, interactive design</h2>
                    <p>Interaction Designer</p>
                    <p>Sep 2006 - May 2008 (1 year 9 months)</p>

                    <h2>HKU (Hogeschool voor de Kunsten Utrecht)</h2>
                    <p>Thesis mentoring</p>
                    <p>Feb 2007 - Apr 2007 (3 months)</p>
                    <br></br>
                    <br></br>
                    <p>Education</p>
                    <h2>HKU (Hogeschool voor de Kunsten Utrecht)</h2>
                    <p>BA Interaction Design</p>
                    <p>2000 - 2006</p>
                </Col>
                <Col lg={6}>
                </Col>
            </Row>
        </section>
    </Layout>


}

export default Info;